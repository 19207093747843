<template>
  <section>
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm1"
          :model="selectForm1"
          class="select_form"
        >
          <el-form-item label="公司">
            <el-input v-model="selectForm1.Month" placeholder=""></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="new_css calc-height">
      <!--列表-->

      <el-table
        :data="users"
        highlight-current-row
        height="100%"
     
        @current-change="selectCurrentRow"
        class="new_table"
      >
        <el-table-column prop="CreateUserName" label="创建人">
        </el-table-column>
        <el-table-column label="提交的公司" prop="Factory"></el-table-column>
        <el-table-column label="年" prop="RegYear"></el-table-column>
        <el-table-column label="月" prop="RegMonth"></el-table-column>
        <el-table-column prop="PersonsCount" label="人员数"></el-table-column>
        <el-table-column prop="UserMoneyCount" label="实发数"></el-table-column>
        <el-table-column
          prop="ShouldSalaryCount"
          label="应发数"
        ></el-table-column>
        <el-table-column prop="TaxSalaryCount" label="个税数"></el-table-column>
        <el-table-column prop="State" label="状态"></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>

    <el-dialog
      title="薪资考勤"
      :visible.sync="InsuranceVisible"
      v-model="InsuranceVisible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-row>
        <el-col
          v-if="buttonList.length > 0"
          :span="24"
          class="organizetoolbar"
          style="padding-bottom: 0px"
        >
          <el-form :inline="true" ref="selectForm" :model="selectForm">
            <el-form-item label="名称:">
              <el-input
                v-model="selectForm.userName"
                placeholder="名称"
              ></el-input>
            </el-form-item>

            <el-form-item label="审核状态:">
              <el-select
                style="width: 100%"
                clearable
                v-model="State"
                placeholder="审核状态"
              >
                <!-- <el-option label="已提交" value="已提交"></el-option> -->
                <el-option label="退回" value="退回"></el-option>
                <el-option label="发布工资条" value="发布工资条"></el-option>
              </el-select>
            </el-form-item>
            <!-- 这个就是当前页面内，所有的btn列表 -->
            <el-form-item>
              <!-- 这里触发点击事件 -->

              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="getInsuranceData()"
                >查询</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                :disabled="isDisabled"
                @click="handleCommany()"
                >提交审核</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table
        :data="InsuranceData"
        highlight-current-row
        border
        height="500"
        :row-class-name="tableRowClassName"
        @current-change="selectCurrentRow1"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column fixed prop="UserName" label="名称"> </el-table-column>
        <el-table-column fixed prop="Factory" label="公司"> </el-table-column>
        <el-table-column fixed prop="UserDpt" label="部门"> </el-table-column>
        <el-table-column fixed prop="UserPost" label="岗位"> </el-table-column>
        <el-table-column prop="UserState" label="用户状态"> </el-table-column>
        <el-table-column
          prop="StateDate"
          label="状态时间"
          :formatter="formatStartTime"
        >
        </el-table-column>
        <el-table-column prop="RegYear" label="所属年"> </el-table-column>
        <el-table-column prop="RegMonth" label="所属月"> </el-table-column>
        <!-- <el-table-column fixed label="身份证号" show-overflow-tooltip width="150" prop="IdCard" ></el-table-column> -->
        <el-table-column
          label="工资类型"
          width="120"
          prop="SalaryType"
        ></el-table-column>
        <el-table-column
          label="用工性质"
          width="120"
          prop="ContractNature"
        ></el-table-column>

        <el-table-column
          label="应出勤天数"
          width="120"
          prop="UserAttend"
        ></el-table-column>
        <el-table-column
          label="实际出勤天数"
          prop="UserRealAttend"
        ></el-table-column>
        <el-table-column
          label="办事处出勤天数"
          prop="OutAttend"
        ></el-table-column>
        <el-table-column
          label="回公司天数"
          prop="BackFactoryDay"
        ></el-table-column>
        <el-table-column label="探亲天数" prop="BackFamilyDa"></el-table-column>
        <el-table-column label="请假天数" prop="AskDay"></el-table-column>
        <el-table-column label="值班天数" prop="OndutyDay"></el-table-column>
        <el-table-column label="加班天数" prop="AddDay"></el-table-column>
        <el-table-column label="夜班天数" prop="NigtyDay"></el-table-column>
        <el-table-column label="岗位工资" prop="WorkSalary"></el-table-column>
        <el-table-column label="职务工资" prop="PostSalary"></el-table-column>
        <el-table-column
          label="职称工资"
          prop="PostionSalary"
        ></el-table-column>
        <el-table-column label="年功工资" prop="YearSalary"></el-table-column>
        <el-table-column
          label="探亲补贴"
          prop="BackFamilySalary"
        ></el-table-column>
        <el-table-column
          label="质量补贴"
          prop="QualitySalary"
        ></el-table-column>
        <el-table-column label="零工" prop="OddSalary"></el-table-column>
        <el-table-column
          label="值班日保健费"
          prop="OndutyCareSalary"
        ></el-table-column>
        <el-table-column
          label="日保健费"
          prop="CareDaySalary"
        ></el-table-column>
        <el-table-column label="保健费" prop="CareSalary"></el-table-column>
        <el-table-column
          label="日工资出勤天数"
          prop="DayAttend"
        ></el-table-column>
        <el-table-column label="工人系数" prop="WorkerMod"></el-table-column>
        <el-table-column label="计件天数" prop="PieceDay"></el-table-column>
        <el-table-column label="日工资单价" prop="DaySalary"></el-table-column>
        <el-table-column
          label="日工资小计"
          prop="DaySalaryCount"
        ></el-table-column>
        <el-table-column label="计件工资" prop="PieceSalary"></el-table-column>
        <el-table-column label="缺勤扣款" prop="LeaveSalary"></el-table-column>
        <el-table-column
          label="伙食单价"
          prop="EateDaySalary"
        ></el-table-column>
        <el-table-column
          label="基本工资小计"
          prop="BasicSalary"
        ></el-table-column>
        <el-table-column label="绩效工资" prop="PerfSalary"></el-table-column>
        <el-table-column label="值班补贴" prop="OnWorkSalary"></el-table-column>
        <el-table-column
          label="加班补贴"
          prop="AddWorkSalary"
        ></el-table-column>

        <el-table-column label="晚班补贴" prop="NigtySalary"></el-table-column>

        <el-table-column
          label="特岗补贴"
          prop="SpecialSalary"
        ></el-table-column>
        <el-table-column label="伙食补贴" prop="EateSalary"></el-table-column>
        <el-table-column
          label="驻外补贴"
          prop="OutsideSalary"
        ></el-table-column>
        <el-table-column
          label="实际驻外补贴"
          prop="RealOutsideSalary"
        ></el-table-column>
        <el-table-column label="车辆补贴" prop="BusMoney"></el-table-column>
        <el-table-column label="租房补贴" prop="HouseSalary"></el-table-column>
        <el-table-column label="其他补贴" prop="OtherMoney"></el-table-column>
        <el-table-column label="正负激励" prop="PowerSalary"></el-table-column>
        <el-table-column
          label="补贴小计"
          prop="SubsidySalary"
        ></el-table-column>
        <el-table-column label="应发工资" prop="ShouldSalary"></el-table-column>
        <el-table-column label="个人欠款" prop="SellSalary"></el-table-column>
        <el-table-column label="保险扣除" prop="SafeMoney"></el-table-column>
        <el-table-column
          label="住房公积金扣除"
          prop="AccumuSalary"
        ></el-table-column>
        <el-table-column
          label="个税基数扣除项"
          prop="PersonBasicSalary"
        ></el-table-column>
        <el-table-column label="其他扣款" prop="OtherSalary"></el-table-column>
        <el-table-column
          label="个人所得税"
          prop="PersonTaxSalary"
        ></el-table-column>
        <el-table-column label="爱心基金" prop="LoveMoney"></el-table-column>
        <el-table-column
          label="上半月以发放"
          prop="IssuedSalary"
        ></el-table-column>
        <el-table-column
          label="实发工资"
          fixed="right"
          prop="UserMoney"
        ></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-size="pageSize1"
        layout="total, prev, pager, next, jumper,total"
        :page-count="total1"
        :total="totaldata1"
      >
      </el-pagination>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
//  import moment from 'moment/moment.js'
import * as validate from "../../../util/validate";
import {
  SalaryReportGetGroupInfoPageList,
  GetSalaryReportList,
  SalaryReportSubmitInfo,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
    return {
      PositionLists: [],
      fileListUpload: [],
      searchVal: "",
      disbtn: false,
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      currentPage1: 1, // 当前页码
      total1: null, // 总条数
      totaldata1: null,
      pageSize1: 20, // 每页的数据条数
      page1: 1,
      isDisabled: false,
      SakaryVisible: false,
      InsuranceVisible: false,
      SakaryAllFormVisible: false,
      buttonListmsg: "",
      users: [],
      InsuranceData: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      multipleSelection: [],
      PId: "",
      State: null,
      Factory: "",
      selectForm: {
        userName: "",
      },
      selectForm1: {
        Year: "",
        Month: "",
      },
      SakaryForm: [],
      filters: {
        name: "",
      },
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (row.State == "已提交") {
        return "warning-row";
      }
      return "";
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectCurrentRow1(val) {
      this.currentRow1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.page1 = val;
      this.getInsuranceData(false);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        Factory: this.selectForm1.Month,
      };

      SalaryReportGetGroupInfoPageList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getInsuranceData(isCurrent = true) {
      if (isCurrent) {
        this.page1 = 1;
      }
      let para = {
        page: this.page1,
        size: this.pageSize1,
        PId: this.PId,
        userName: this.selectForm.userName,
        factory: this.Factory,
      };
      GetSalaryReportList(para).then((res) => {
        console.log(res);
        this.total1 = res.data.response.pageCount;
        this.totaldata1 = res.data.response.dataCount;
        this.InsuranceData = res.data.response.data;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      this.PId = row.Id;
      this.Factory = row.Factory;
      this.getInsuranceData();

      this.InsuranceVisible = true;
    },
    handleCommany() {
      if (!this.State) {
        this.$message({
          message: "请选择审核状态！",
          type: "error",
        });
        return;
      }
      let para = {
        PId: this.PId,
        factory: this.Factory,
        State: this.State,
      };
      SalaryReportSubmitInfo(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "设置成功",
            type: "success",
            duration: 5000,
          });
          this.getInsuranceData();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>

